<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row>
      <b-col cols="4">
        <div class="form-group mb-0">
          <label for="tonometria-aparelho">Aparelho de medição</label>
          <multiselect
            :value="'Tonômetro de sopro'"
            id="tonometria-aparelho"
            :options="['Tonômetro de sopro']"
            :option-height="40"
            :showLabels="false"
            :searchable="false"
            :allowEmpty="false"
            placeholder="Selecionar"
            class="with-border"
            disabled
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </div>
      </b-col>
      <b-col cols="2">
        <div class="form-group mb-0">
          <label for="tonometria-horario">Horário</label>
          <input
            autocomplete="off"
            :value="horario"
            :readonly="!canEdit"
            @input="el => updateTonometria('horario', el.target.value)"
            @blur="updateMedicalRecord(form)"
            id="tonometria-horario"
            type="text"
            class="form-control"
            placeholder="00:00"
            v-mask="'##:##'"
          />
        </div>
      </b-col>
      <b-col cols="3">
        <div class="form-group mb-0">
          <label for="tonometria-direito">Olho direito</label>
          <div class="input-eye">
            <p class="tag-eye"><EyeFill /> D</p>
            <input
              autocomplete="off"
              :value="form.fields.olhoDireito"
              :readonly="!canEdit"
              @input="el => updateTonometria('olhoDireito', el.target.value)"
              @blur="updateMedicalRecord(form)"
              id="tonometria-direito"
              type="text"
              class="form-control"
              placeholder="Resultado"
            />
          </div>
        </div>
      </b-col>
      <b-col cols="3">
        <div class="form-group mb-0">
          <label for="tonometria-esquerdo">Olho esquerdo</label>
          <div class="input-eye">
            <p class="tag-eye"><EyeFill /> E</p>
            <input
              autocomplete="off"
              :value="form.fields.olhoEsquerdo"
              :readonly="!canEdit"
              @input="el => updateTonometria('olhoEsquerdo', el.target.value)"
              @blur="updateMedicalRecord(form)"
              id="tonometria-esquerdo"
              type="text"
              class="form-control"
              placeholder="Resultado"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ChevronDown from '@/assets/icons/chevron-down.svg'
import EyeFill from '@/assets/icons/eye-fill.svg'
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'

export default {
  components: {
    ChevronDown,
    EyeFill,
    FormWrapper,
  },
  data () {
    return {
      horario: this.moment().format('HH:mm'),
    }
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.tonometria,
    })
  },
  watch: {
    'form.fields.horario': function(value) {
      this.horario = value
    }
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/tonometria', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },

    updateTonometria (key, value) {
      if (!this.form?.fields?.horario)
        this.updateForm('horario', this.horario ?? this.moment().format('HH:mm'))
      this.updateForm('aparelho', 'Tonômetro de sopro')
      this.updateForm(key, value)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    }
  },
}
</script>
